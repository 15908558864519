.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  width: 100%;
}

.hero-primary-title {
  color: #50fa7b;
  font-size: 3rem;
  text-align: center;
}

.hero-secondary-title {
  color: #ff79c6;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
}

.hero-down-link {
  background-color: transparent;
  border: 0;
  color: #50fa7b;
  transition: all 0.5s ease;
  margin-top: 5rem;
}

.hero-down-link:hover {
  color: #ff79c6;
  transform: translateY(5px);
  transition: all 0.5s ease;
}
