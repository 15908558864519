.work-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  width: 100%;
}

.work-title {
  color: #50fa7b;
  font-size: 2rem;
  padding: 1rem;
  text-decoration: underline;
}

.work-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
}

.work-tech-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.work-tech-heading {
  color: #50fa7b;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.work-tech-logos {
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: center;
  align-items: center;
  justify-items: center;
  row-gap: 20px;
  column-gap: 20px;
  height: auto;
  width: 100%;
  padding: 1rem 0rem;
  overflow-x: hidden;
}

.work-tech {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.work-tech-title {
  height: 19px;
  width: 100%;
  color: #f8f8f2;
  text-align: center;
}

.work-tech-logo {
  transition: all 0.5s ease;
  width: 80px;
}

.work-tech-logo:hover {
  transform: translateY(-5px);
  transition: all 0.5s ease;
}

.work-projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0rem;
}

.work-projects-heading {
  color: #50fa7b;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.work-projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  overflow-x: hidden;
}

.work-project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #44475a;
  border-radius: 10px;
  height: auto;
  width: 300px;
  margin-bottom: 1rem;
  padding: 1rem;
  overflow-x: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.work-project-title {
  color: #50fa7b;
}

.work-project-info {
  color: #f8f8f2;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  line-height: 1.5rem;
}

.work-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.work-link-container {
  background-color: #ff79c6;
  border-radius: 10px;
  width: 200px;
  padding: 0.5rem 0rem;
  margin-top: 1rem;
  text-align: center;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.5s ease;
}

.work-link-container:hover,
.work-link-container:active {
  background-color: #d166a3;
  transition: all 0.5s ease;
}

.work-link {
  text-decoration: none;
  color: #282a36;
}

@media (min-width: 481px) {
  .work-tech-logos {
    grid-template-columns: repeat(5, auto);
  }

  .work-projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
  }

  .work-project-info {
    margin-bottom: 0rem;
  }

  .work-project-card {
    height: 500px;
    width: 350px;
    margin: 0.5rem;
  }

  .work-link-container {
    margin-top: 1rem;
  }

  .work-links {
    display: flex;
    flex-direction: column;
    padding: 0rem;
  }
}

@media (min-width: 769px) {
  .work-container {
    height: auto;
  }

  .work-main {
    height: auto;
    padding: 2rem 0rem;
  }

  .work-tech-heading {
    font-size: 1.5rem;
  }

  .work-tech-logos {
    grid-template-columns: repeat(6, auto);
  }

  .work-projects-heading {
    font-size: 1.5rem;
  }

  .work-projects {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .work-project-card {
    height: 500px;
    width: 350px;
    margin: 0.5rem;
  }

  .work-links {
    display: flex;
    flex-direction: column;
    padding: 0rem;
  }

  .work-link-container {
    margin-top: 1rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

@media (min-width: 1025px) {
  .work-container {
    height: 100vh;
  }

  .work-main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .work-tech-container {
    justify-content: flex-start;
    height: auto;
    margin-top: 3rem;
  }

  .work-tech-logos {
    grid-template-columns: repeat(3, auto);
  }

  .work-projects-container {
    justify-content: flex-start;
    height: auto;
    margin-top: 3rem;
  }

  .work-projects {
    padding-top: 1rem;
    margin-top: 0rem;
  }

  .work-project-card {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}
