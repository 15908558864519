.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  width: 100%;
}

.about-title {
  color: #50fa7b;
  font-size: 2rem;
  padding: 1rem;
  width: 100%;
  text-align: center;
  text-decoration: underline;
}

.about-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: auto;
  padding: 2rem 0rem;
}

.about-photo-container {
  height: 300px;
  width: 300px;
  overflow: hidden;
  border-radius: 50%;
}

.about-photo {
  height: auto;
  width: 300px;
}

.about-card {
  background-color: #44475a;
  border-radius: 10px;
  height: auto;
  width: 300px;
  padding: 1rem;
  margin-top: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.about-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1.5rem;
  color: #f8f8f2;
  height: 100%;
}

@media (min-width: 481px) {
  .about-main {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
  }

  .about-card {
    margin: 1rem;
  }
}

@media (min-width: 1025px) {
  .about-container {
    height: auto;
    padding: 0rem;
  }

  .about-main {
    flex-direction: row;
    justify-content: center;
    height: auto;
    width: 100%;
    padding: 2rem 0rem;
  }

  .about-card {
    height: 200px;
    width: 500px;
  }
}
