.commits-container {
  display: grid;
  grid-template-columns: repeat(52, 10px);
  justify-content: center;
  grid-gap: 6px;
  height: fit-content;
  width: 100%;
  overflow-x: hidden;
}

.commits-col {
  display: grid;
  justify-content: center;
  grid-gap: 6px;
  height: fit-content;
  width: 10px;
}

.commits-box {
  height: 10px;
  width: 10px;
  border-radius: 2px;
}

@media (min-width: 769px) {
  .commits-container {
    grid-template-columns: repeat(52, 10px);
  }
}
