@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  width: 100%;
  background-color: #282a36;
}

html {
  font-size: 16px;
  font-family: 'Lato', sans-serif;
}
