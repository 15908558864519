.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  width: 100%;
}

.contact-heading {
  color: #50fa7b;
  font-size: 2rem;
  padding: 1rem;
  width: 100%;
  text-align: center;
  text-decoration: underline;
}

.contact-form-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 2rem 0rem;
  width: 100%;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: auto;
  width: 300px;
  padding: 1rem;
  background-color: #44475a;
  border-radius: 10px;
}

.contact-field-container {
  margin-bottom: 0.5rem;
  width: 100%;
}

label {
  color: #50fa7b;
}

input,
textarea {
  background-color: #282a36;
  border: 0;
  border-radius: 5px;
  width: 100%;
  margin-top: 0.5rem;
  color: #f8f8f2;
  font-family: 'Lato', sans-serif;
  padding: 0.5rem;
}

input {
  height: 2rem;
}

textarea {
  height: 6rem;
  resize: none;
}

.contact-error {
  color: #ff5555;
  height: 19px;
}

.contact-form-btn {
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 5px;
  background-color: #ff79c6;
  color: #282a36;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  width: 150px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.contact-form-btn:hover,
.contact-form-btn:active {
  background-color: #d166a3;
  transition: all 0.5s ease;
}

.contact-success {
  color: #50fa7b;
  margin-top: 0.5rem;
}

.contact-social-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
}

.contact-social-link {
  padding: 0rem 1rem;
}

@media (min-width: 481px) {
  .contact-container {
    height: auto;
  }

  .contact-form-container {
    height: auto;
    padding: 2rem 0rem;
  }

  .contact-form {
    width: 400px;
  }
}

@media (min-width: 1025px) {
  .contact-container {
    height: 100vh;
  }

  .contact-form-container {
    height: calc(100vh - 67px - 71px);
  }
}
