@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
.about-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: auto;
  width: 100%;
}

.about-title {
  color: #50fa7b;
  font-size: 2rem;
  padding: 1rem;
  width: 100%;
  text-align: center;
  text-decoration: underline;
}

.about-main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  height: auto;
  padding: 2rem 0rem;
}

.about-photo-container {
  height: 300px;
  width: 300px;
  overflow: hidden;
  border-radius: 50%;
}

.about-photo {
  height: auto;
  width: 300px;
}

.about-card {
  background-color: #44475a;
  border-radius: 10px;
  height: auto;
  width: 300px;
  padding: 1rem;
  margin-top: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.about-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  line-height: 1.5rem;
  color: #f8f8f2;
  height: 100%;
}

@media (min-width: 481px) {
  .about-main {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: auto;
    width: 100%;
  }

  .about-card {
    margin: 1rem;
  }
}

@media (min-width: 1025px) {
  .about-container {
    height: auto;
    padding: 0rem;
  }

  .about-main {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    height: auto;
    width: 100%;
    padding: 2rem 0rem;
  }

  .about-card {
    height: 200px;
    width: 500px;
  }
}

.contact-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: auto;
  width: 100%;
}

.contact-heading {
  color: #50fa7b;
  font-size: 2rem;
  padding: 1rem;
  width: 100%;
  text-align: center;
  text-decoration: underline;
}

.contact-form-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: auto;
  padding: 2rem 0rem;
  width: 100%;
}

.contact-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  height: auto;
  width: 300px;
  padding: 1rem;
  background-color: #44475a;
  border-radius: 10px;
}

.contact-field-container {
  margin-bottom: 0.5rem;
  width: 100%;
}

label {
  color: #50fa7b;
}

input,
textarea {
  background-color: #282a36;
  border: 0;
  border-radius: 5px;
  width: 100%;
  margin-top: 0.5rem;
  color: #f8f8f2;
  font-family: 'Lato', sans-serif;
  padding: 0.5rem;
}

input {
  height: 2rem;
}

textarea {
  height: 6rem;
  resize: none;
}

.contact-error {
  color: #ff5555;
  height: 19px;
}

.contact-form-btn {
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 5px;
  background-color: #ff79c6;
  color: #282a36;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  width: 150px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.contact-form-btn:hover,
.contact-form-btn:active {
  background-color: #d166a3;
  transition: all 0.5s ease;
}

.contact-success {
  color: #50fa7b;
  margin-top: 0.5rem;
}

.contact-social-links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 1rem;
  width: 100%;
}

.contact-social-link {
  padding: 0rem 1rem;
}

@media (min-width: 481px) {
  .contact-container {
    height: auto;
  }

  .contact-form-container {
    height: auto;
    padding: 2rem 0rem;
  }

  .contact-form {
    width: 400px;
  }
}

@media (min-width: 1025px) {
  .contact-container {
    height: 100vh;
  }

  .contact-form-container {
    height: calc(100vh - 67px - 71px);
  }
}

.commits-container {
  display: grid;
  grid-template-columns: repeat(52, 10px);
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 6px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  overflow-x: hidden;
}

.commits-col {
  display: grid;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 6px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 10px;
}

.commits-box {
  height: 10px;
  width: 10px;
  border-radius: 2px;
}

@media (min-width: 769px) {
  .commits-container {
    grid-template-columns: repeat(52, 10px);
  }
}

.hero-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: calc(100vh - 80px);
  width: 100%;
}

.hero-primary-title {
  color: #50fa7b;
  font-size: 3rem;
  text-align: center;
}

.hero-secondary-title {
  color: #ff79c6;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
}

.hero-down-link {
  background-color: transparent;
  border: 0;
  color: #50fa7b;
  transition: all 0.5s ease;
  margin-top: 5rem;
}

.hero-down-link:hover {
  color: #ff79c6;
  -webkit-transform: translateY(5px);
          transform: translateY(5px);
  transition: all 0.5s ease;
}

.nav-bar-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1.5rem;
}

.nav-bar-logo-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: auto;
}

.nav-bar-logo-title {
  color: #50fa7b;
  font-size: 1.5rem;
  margin-left: 0.5rem;
  transition: all 0.5s ease;
}

.nav-bar-logo-title:hover {
  color: #ff79c6;
  transition: all 0.5s ease;
}

.nav-bar-burger-menu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #50fa7b;
  background-color: transparent;
  border: 0;
}

.nav-bar-burger-menu.active {
  color: #ff79c6;
}

.nav-bar-links.mobile {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  top: 80px;
  left: 0px;
  width: 100%;
  height: 8rem;
  z-index: 100;
  border-top: 1px solid #44475a;
  border-bottom: 1px solid #44475a;
}

.nav-bar-links {
  display: none;
}

.nav-bar-link {
  color: #50fa7b;
  text-decoration: none;
  text-align: center;
  width: 100%;
  transition: all 0.5s ease;
}

.nav-bar-link:active,
.nav-bar-link:hover {
  color: #ff79c6;
  transition: all 0.5s ease;
  text-decoration: underline;
}

@media (min-width: 769px) {
  .nav-bar-container {
    padding: 1.5rem 3rem;
  }

  .nav-bar-burger-menu {
    display: none;
  }

  .nav-bar-links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 40%;
    height: 100%;
  }
}

.work-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: auto;
  width: 100%;
}

.work-title {
  color: #50fa7b;
  font-size: 2rem;
  padding: 1rem;
  text-decoration: underline;
}

.work-main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  height: auto;
}

.work-tech-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 1rem;
}

.work-tech-heading {
  color: #50fa7b;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.work-tech-logos {
  display: grid;
  grid-template-columns: repeat(3, auto);
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  height: auto;
  width: 100%;
  padding: 1rem 0rem;
  overflow-x: hidden;
}

.work-tech {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.work-tech-title {
  height: 19px;
  width: 100%;
  color: #f8f8f2;
  text-align: center;
}

.work-tech-logo {
  transition: all 0.5s ease;
  width: 80px;
}

.work-tech-logo:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  transition: all 0.5s ease;
}

.work-projects-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 1rem 0rem;
}

.work-projects-heading {
  color: #50fa7b;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.work-projects {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: auto;
  overflow-x: hidden;
}

.work-project-card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #44475a;
  border-radius: 10px;
  height: auto;
  width: 300px;
  margin-bottom: 1rem;
  padding: 1rem;
  overflow-x: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.work-project-title {
  color: #50fa7b;
}

.work-project-info {
  color: #f8f8f2;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  line-height: 1.5rem;
}

.work-links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.work-link-container {
  background-color: #ff79c6;
  border-radius: 10px;
  width: 200px;
  padding: 0.5rem 0rem;
  margin-top: 1rem;
  text-align: center;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.5s ease;
}

.work-link-container:hover,
.work-link-container:active {
  background-color: #d166a3;
  transition: all 0.5s ease;
}

.work-link {
  text-decoration: none;
  color: #282a36;
}

@media (min-width: 481px) {
  .work-tech-logos {
    grid-template-columns: repeat(5, auto);
  }

  .work-projects {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    height: auto;
  }

  .work-project-info {
    margin-bottom: 0rem;
  }

  .work-project-card {
    height: 500px;
    width: 350px;
    margin: 0.5rem;
  }

  .work-link-container {
    margin-top: 1rem;
  }

  .work-links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0rem;
  }
}

@media (min-width: 769px) {
  .work-container {
    height: auto;
  }

  .work-main {
    height: auto;
    padding: 2rem 0rem;
  }

  .work-tech-heading {
    font-size: 1.5rem;
  }

  .work-tech-logos {
    grid-template-columns: repeat(6, auto);
  }

  .work-projects-heading {
    font-size: 1.5rem;
  }

  .work-projects {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .work-project-card {
    height: 500px;
    width: 350px;
    margin: 0.5rem;
  }

  .work-links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0rem;
  }

  .work-link-container {
    margin-top: 1rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

@media (min-width: 1025px) {
  .work-container {
    height: 100vh;
  }

  .work-main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }

  .work-tech-container {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    height: auto;
    margin-top: 3rem;
  }

  .work-tech-logos {
    grid-template-columns: repeat(3, auto);
  }

  .work-projects-container {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    height: auto;
    margin-top: 3rem;
  }

  .work-projects {
    padding-top: 1rem;
    margin-top: 0rem;
  }

  .work-project-card {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}

.main-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: auto;
  width: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  width: 100%;
  background-color: #282a36;
}

html {
  font-size: 16px;
  font-family: 'Lato', sans-serif;
}

